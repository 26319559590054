import Regans from "../../assets/Images/Landingpage/Rgans.svg";

const IWF = () => {
  return (
   <div className="flex flex-col lg:flex-row">
      <div className="relative lg:w-[697px] w-full">
        <img src={Regans} alt="Regans" className="object-cover w-full lg:h-full " />
      </div>

      <div className="bg-black text-white w-full lg:w-[815px] pt-10 md:pt-16 pb-8 md:pb-16 px-8 md:px-16 ">
        <h1 className="font-normal text-base font-Jockey_One">
          FORTITUDE{" "}
        </h1>
        <h1 className="font-normal text-2xl md:text-3xl font-Jockey_One">
          MATURED EXCLUSIVELY IN PEDRO XIMENEZ CASKS{" "}
        </h1>
        <p className="font-medium text-lg md:text-xl pt-4 md:pt-6 font-Hind">
          THIS SINGLE HAS BEEN MATURED EXCLUSIVELY IN PEDRO XIMENEZ CASKS TO
          CREATE A FULL FLAVOURED WHISKEY WITH A STRONG INFLUENCE OF SHERRY.
          SPECIALLY CRAFTED TO CELEBRATE THE STRENGTH OF CHARACTHER AND
          RESOLVE OF OUR CO-FOUNDER AND PASSIONSATE IRISHMAN HENRY J. EGAN.{" "}
        </p>

        <div className="font-normal text-sm md:text-base pt-9 font-Inter space-y-4">
          <p>
            Henry J. Egan, co-founder of P.&H. Egan, was a formidable
            businessman and passiionate irishman. A founding member of the
            Tuilamore Land League, he was once arrested for organising a rally
            supporting fellow irish nationalist and land reform agitator
            Charles Stewart Parnell. However, his brief sojourn in Naas jail
            did little to damage the company's commercial operation.
          </p>
          <p>
            As wine & spirit merchants, Egan's had developed an extentsive
            trade- both domestically and for export - bottling and bonding
            many Irish Whiskey's as well as importing large quantities of
            sherry, port and rum.
          </p>
        </div>
        <div className="space-y-5 pt-4">
          <h1 className="font-bold font-Inter text-base md:text-lg">TASTING NOTES</h1>
          
          <div className="space-y-5 font-normal">
            <p className="font-Inter md:text-base">
              <span className="font-semibold font-Inter">Nose-</span>{" "}
              Heavy(red) sherry, honey, raising, and a hint of marzipan
            </p>
            <p className="font-Inter md:text-base">
              <span className="font-semibold font-Inter">Palate-</span> Full
              of complexity, strong Influence of sherry, (reflected in the
              aroma), mixed fruity, notably, raisins, and sutanas.
            </p>
            <p className="font-Inter md:text-base">
              <span className="font-semibold font-Inter">Linger-</span> Good,
              well balanced-long.
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default IWF;
