import { Link, useNavigate } from "react-router-dom";
import "./styles.css"
import { useState } from "react";

const ConfirmPage = () => {
    const navigate = useNavigate();
    const [getYear, setGetYear] = useState(null);
    
    console.log(getYear)

    const onSubmitHandler = (e) => {
        e.preventDefault();

        const currentYear = new Date().getFullYear();
        const customersAge = Math.round(currentYear - getYear);

        if(customersAge >= 18){
            return navigate('/product');
        }

        return navigate('/error');  
    }
            
    return (
        <main className="absolute bigger text-white top-0 grid h-screen place-content-center w-full bg-gradient-to-r from-indigo-500 ">
            <div className="text-center text-xl md:text-3xl space-y-10">
                <h1>To enter the Uridium-Bereverage Website, you must be <br />of legal drinking age in your country.</h1>
                <form className="mx-auto" onSubmit={onSubmitHandler}>
                    <h1 className="lg:text-xs text-base p-3">Please Enter your Date Of Birth</h1>
                    <div className="mx-auto w-fit space-y-6" on>
                        <input
                            type="number"
                            id="year"
                            min={0} minLength={4}
                            className="shadow-sm w-[150px] h-[60px] bg-[#dad7d3] border text-gray-900 text-4xl rounded-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                            placeholder="YYYY"
                            required
                            onChange={(e) => setGetYear(e.target.value)}
                        />
                        
                        <button
                            type="submit"
                            className="text-black font-Inter text-4xl space-y-6 w-full lg:w-[119px] h-[60px] bg-black hover:bg-slate-400 focus:ring-4 focus:outline-none focus:ring-black rounded-sm font-normal px-2 py-0.5 text-center dark:bg-slate-200 dark:hover:bg-slate-400 dark:focus:ring-slate-50"
                            >
                            OK
                        </button>

                        <div className="flex justify-center mb-4">
                            <input disabled id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                            <label htmlFor="checked-checkbox" className="ms-2 lg:text-sm text-base font-medium text-white dark:text-gray-500">Remenber Me</label>
                        </div>
                    </div>
                </form>
            </div>  
        </main>
    )
}

export default ConfirmPage;