import Cocal from "../../assets/Images/Landingpage/Cocal.svg";

const CocalH = () => {
  return (
    
    <div className="flex flex-col lg:flex-row">
      <div className="relativelg:w-[697px]">
        <img src={Cocal} alt="Cocal" className=" object-cover w-full md:h-full" />
      </div>

      <div className="bg-black text-white w-full lg:w-[815px] pt-10 md:pt-16 pb-8 md:pb-16 px-8 md:px-16  ">
        <h1 className="font-normal text-base md:text-sm font-Jockey_One">
          CLASSICO{" "}
        </h1>
        <h1 className="font-normal text-2xl md:text-3xl font-Jockey_One">
          ELEVATES SOCIAL OCCASIONS{" "}
        </h1>
        <p className="font-medium text-lg md:text-xl pt-4 font-Hind">
          HERBAL SPIRIT IS REMARKABLE VERSATILE AS A COCKTAIL INGREDIENT AND{" "}
          CAN BE USE TO ADD AN EXCOTIC TWIST TO A SIMPLE MIXED DRINKS.{" "}
        </p>

        <div className="font-normal text-sm md:text-base pt-5 font-Inter">
          <p>
            Our unique botanical bland includes Cora Leaf, Guarana, Ginger and
            Juniper. We extract these delicate flavours using a proprietary
            steam distillation process pioneered by the perfume industry. This
            technique ensures the natural flavours and aromas are never
            compromised and this is clear from the first sip. Pour it over
            tonic, in a spiritz or as a chilled shot.
            <br />
          </p>
        </div>

        <div className="space-y-5 pt-4">
          <h1 className="font-bold text-base md:text-lg font-Inter">SIGNATURE SERVER</h1>

          <div className="space-y-5 font-normal">
            <p className="font-Inter md:text-base">
              <span className="font-semibold md:text-base font-Inter ">COCASHORT</span>{" "}
              Keep it simple with a shot served at sub zero
            </p>
            <p className="font-Inter md:text-base">
              <span className="font-semibold md:text-base font-Inter ">COCABOMB</span>{" "}
              Using our signature glass, layer Cocalero over year favourite
              mixer or energy drink.
            </p>
            <p className="font-Inter md:text-base">
              <span className="font-semibold md:text-base font-Inter ">COCASPIRITZ</span>{" "}
              Sever over ice and top with procecco and soda. Garnish with a
              citrus peel.
            </p>
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default CocalH;
