import johnson from "../../assets/Images/Aboutpage/johnson.svg";
import Coo from "../../assets/Images/Aboutpage/Coo.svg";
// import Hands from "../../assets/Images/Aboutpage/Hands.svg";
// import Good from "../../assets/Images/Aboutpage/Good.svg";
// import safety from "../../assets/Images/Aboutpage/safety.svg";
// import Okayhand from "../../assets/Images/Aboutpage/Okayhand.svg";
import "./stylingFiles.css"



const Weare = () => {

  return (
    <main>
        <div className="flex flex-col lg:flex-row px-10 lg:gap-20 pb-20 w-full ">

            <div className="w-full md:h-[850px] h-[1220px] h- pt-9 about">
                <div className="space-y-7">
                    <h1 className="font-bold text-[20px] lg:text-[40px] font-Rubik">Who We Are</h1>

                    <h1 className="font-bold text-[20px] font-DM_Sans">Uridium Delight & Beverages</h1>
                    <p className="font-Inter font-normal text-base md:text-lg">
                     <span className="font-semibold">Uridium Delight & Beverages </span>is a subordinary under the Uridium Group, your premier source for innovative and high-quality Fast-Moving Consumer Goods (FMCG) in the beverage industry. At Uridium Delight & Beverages, we pride ourselves on our commitment to excellence, sustainability, and customer satisfaction.
                    </p>
                    <h1 className="font-bold text-[20px] font-DM_Sans">Our Mission</h1>
                    <p className="font-Inter font-normal text-base md:text-lg">
                    Our mission is to deliver exceptional beverage products that cater to our global consumers' diverse tastes and preferences. We strive to combine creativity and innovation to develop strategies that delight our customers.
                    </p>
                    <h1 className="font-bold text-[20px] font-DM_Sans">Our Vision</h1>
                    <p className="font-Inter font-normal text-base md:text-lg">
                    Our mission is to deliver exceptional beverage products that cater to our global consumers' diverse tastes and preferences. We strive to combine creativity and innovation to develop strategies that delight our customers.
                    </p>
                    <h1 className="font-bold text-[20px] font-DM_Sans">Our Products</h1>
                    <p className="font-Inter font-normal text-base md:text-lg">
                    We offers a wide range of products that satisfy’s customers wants. Our products are crafted with the finest ingredients, ensuring that every sip is a testament to our commitment to quality and taste.
                    </p>
                </div>
            </div>

            <div className="w-full lg:w-[487.px] px-10 place-content-center">
                <div className="flex flex-col md:flex-row gap-6 md:pt-10 w-full ">
                    <div className="relative"> 
                        <img src={johnson} alt="johnson" className="md:h-[300px] md:w-[280px]] "  />
                        <div className="absolute bottom-5 left-2 px-1">
                            <h1 className="font-bold text-base text-white">JOHNSON ADEWUSI</h1>
                            <p className="font-extrabold text-sm text-white ">CEO, Co-Founder Uriduim Group</p>
                        </div>
                    </div>
                    <div className="relative">
                        <img src={Coo} alt="Emmanuel" className="md:h-[300px] md:w-[280px]" />
                        <div className="absolute bottom-5 left-2 px-1.5">
                            <h1 className="font-bold text-base text-white">EMMANUEL ODIBOH</h1>
                            <p className="font-extrabold text-sm text-white ">C.E.O, Uridium Delight & Beverages </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="w-full lg:h-[939px] lg:w-[487px] px-10 bg-black rounded-3xl pt-9">
            <div className="text-center pb-7 border-b-2">
                <div className="w-fit mx-auto">
                    <img src={Hands} alt="" className="h-[40px] w-[40px] " />
                </div>
                <div className="text-white">
                    <h1 className="font-bold text-lg pt-5 font-DM_Sans">Trustworthy</h1>
                    <p className="font-normal text-[15px] pt-3 font-Inter">Lorem ipsum dolor sit amet consectetur. Fermentum <br />nunc gravida in id nec cum pretium. Ac nisl habitasse <br />auctor massa feugiat turpis dignissim ullamcorper.</p>
                </div>
            </div>
            
            <div className="text-center py-7 border-b-2">
                <div className="w-fit mx-auto">
                    <img src={Good} alt="" className="h-[40px] w-[40px] " />
                </div>
                <div className="text-white">
                    <h1 className="font-bold text-lg pt-5 font-DM_Sans">Accessible</h1>
                    <p className="font-normal text-[15px] pt-3 font-Inter">Lorem ipsum dolor sit amet consectetur. Egestas <br />tortor quis tincidunt pharetra. Vitae sed pulvinar velit <br />ullamcorper massa ut. Congue mauris molestie.</p>
                </div>
            </div>

            <div className="text-center py-7 border-b-2 " >
                <div className="w-fit mx-auto">
                    <img src={safety} alt="" className="h-[40px] w-[40px] " />
                </div>
                <div className="text-white">
                    <h1 className="font-bold text-lg pt-5 font-DM_Sans">Durable</h1>
                    <p className="font-normal text-[15px] pt-3 font-Inter">Lorem ipsum dolor sit amet consectetur. Quis dis <br />maecenas elementum quis mauris felis. Quis tellus <br />urna non vel duis nam sed imperdiet. Sem vitae.</p>
                </div>
            </div>

            <div className="text-center py-7">
                <div className="w-fit mx-auto">
                    <img src={Okayhand} alt="" className="h-[40px] w-[40px] " />
                </div>
                <div className="text-white">
                    <h1 className="font-bold text-lg pt-5 font-DM_Sans">Classic</h1>
                    <p className="font-normal text-[15px] pt-3 font-Inter">Lorem ipsum dolor sit amet consectetur. Tristique <br />gravida scelerisque ornare vestibulum. Turpis mi <br />mauris et tempus egestas tempus sed lorem ut.</p>
                </div>
            </div>
        </div>
        /* <div className="py-6">
            <h1 className="font-bold text-[20px] lg:text-[40px] font-Rubik">As a Distributor</h1>
            <p className="font-Inter font-normal text-base">
            Lorem ipsum dolor sit amet consectetur. At a tincidunt elit
            pharetra erat faucibus <br />
            dolor tellus. Aliquet sit lobortis est venenatis adipiscing id ac
            dignissim mollis. <br />
            Massa at sapien eu pellentesque arcu tristique felis. Amet sit ut{" "}
            <br />
            sed in neque. Sit ut justo turpis id. Consequat eleifend porta
            malesuada et dolor non purus sit ornare. <br />
            Risus nibh.
            </p>
            <p className="font-Inter font-normal text-base pt-7">
            Lorem ipsum dolor sit amet consectetur. Amet ultrices ornare
            pretium arcu <br />
            tellus. Duis velit lorem ultrices sed nunc vestibulum amet tempor
            maecenas. Eu <br />
            amet donec sagittis pretium etiam commodo senectus. Mauris et
            lorem lorem urna <br />
            felis eu augue.
            </p>
        </div> */}
    </main>
  );
};

export default Weare;
