const ErrorPage = () => {
    return (
        <main className="h-screen ">
            <div className="place-content-center h-screen text-center text-white bg-gradient-to-r from-cyan-500 from-5% via-sky-100 via-30% to-blue-300 to-40% ">
                <h1 className="text-8xl font-Jockey_One font-medium p-10">WISE <br />DRINKING</h1>
                <p className="md:text-2xl text-base px-2 font-Inknut_Antiqua space-y-6 ">oop sorry! you are not of drinkable age... </p>
            </div>
        </main>
    )
}

export default ErrorPage;