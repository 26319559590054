import BeFunky from "../../assets/Images/Landingpage/BeFunky.svg";

const IWV = () => {
  return (
    <div className="flex lg:flex-row-reverse flex-col ">
      <div className="relative w-full lg:w-[697px]">
        <img src={BeFunky} alt="BeFunky" className="object-cover w-full md:h-full" />
      </div>

      <div className="bg-white text-black lg:w-[815px] pt-10 md:pt-16 pb-8 md:pb-16 px-8 md:px-16 ">
        <h1 className="font-normal text-base font-Jockey_One ">
          VINTAGE GRAIN{" "}
        </h1>
        <h1 className="font-normal text-2xl md:text-3xl font-Jockey_One ">
          A MELTCULOUSLY HAND SELECTED SINGLE GRAIN{" "}
        </h1>
        <p className="font-medium text-lg md:text-xl pt-3 md:pt-5 font-Hind">
          EGAN'S VINTAGE GRAIN IS A CELERATION OF THE EXTRAORDINARY INDUSTRY
          AND ENDEAVOUR OF OUR FOREFATHERS AND THIER PASSION FOR THE CRAFT OF
          MALTING, BREWING AND BOTTLING.
        </p>

        <div className="font-normal text-sm md:text-base pt-5 font-Inter space-y-4">
          <p>
            Casked in American oak bourbon barrels for a minimum of eight
            years, this single grain Irish whiskey was meticulously
            hand-selected by Joanthan V. Egan, the sixth generation of a
            family of whiskey in thier blood.
          </p>
          <p>
            A truly unique Irish Whiskey expression, Egan's Vintage Grain's
            lighter style compares very favourable to the heavier pot Still
            and Singles Malt cousins.
          </p>
        </div>
        <div className="space-y-5 pt-4">
          <h1 className="font-bold text-base md:text-lg font-Inter">TASTING NOTES</h1>
          
          <div className="space-y-5 text-base font-normal">
            <p className="font-Inter md:text-base">
              <span className="font-semibold font-Inter ">Nose-</span>Vanilla,
              Oak fudge, and caramel notes.
            </p>
            <p className="font-Inter md:text-base">
              <span className="font-semibold font-Inter ">Mouth-</span>
              Charming sweetness and smooth vanilla, with a subtle hint of
              spice.
            </p>
            <p className="font-Inter md:text-base">
              <span className="font-semibold  font-Inter ">Linger-</span>Long,
              gentle and gaining in warmth.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IWV;
