import { Link } from "react-router-dom";
import onboard from "../../assets/Images/Navbar/onboard.png";


const OnboardPage = () => {
    return(
        <main className="min-h-screen">
            <div className="grid grid-cols-1 items-center lg:grid-cols-2 h-full min-h-screen -space-y-36 lg:space-y-0 lg:gap-6 bg-black w-full px-3.5 lg:px-20">
                <div className="w-full">
                    <img src={onboard} alt="onboard" className="object-cover lg:object-scale-down w-full h-[300px] lg:h-[450px] "  />
                </div>
                
                <div className=" w-full">
                    <div>
                        <h1 className="font-black text-xl lg:text-left text-center md:text-[33px] text-white font-Inter ">Please verify your age</h1>
                    </div>

                    <div className="flex flex-col w-full md:flex-row gap-7 pt-5"> 
                        <button type="button" className="px-4 font-Inter py-2 text-[15.75px] w-full lg:w-[170px] font-bold focus:z-10 focus:ring-1 bg-white border  hover:bg-gray-500 hover:text-gray-900 rounded-[4px] ">
                            <Link to="/confirm">I'm 18 or Older</Link>
                        </button>
                        
                        <button type="button" className="px-4 font-Inter py-2 text-[15.75px] w-full lg:w-[170px] font-bold focus:z-10 focus:ring-1  bg-[#A10000] text-white border  hover:bg-[#A10000] hover:text-gray-900 rounded-[4px] ">
                            <Link to="/error"> I'm under 18</Link></button>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default OnboardPage;