import Negro  from "../../assets/Images/Landingpage/Negro.png";


const CocalS = () => {
  return (
    <div className="">
      <div className="flex flex-col lg:flex-row-reverse">
        <div className="relative lg:w-[697px]">
          <img src={Negro} alt="Cocal" className="object-cover w-full lg:h-full" />
        </div>

        <div className="bg-white text-black w-full lg:w-[815px] pt-10 md:pt-16 pb-8 md:pb-16 px-8 md:px-16 ">
          <h1 className="font-normal text-base font-Jockey_One">
            NEGRO{" "}
          </h1>
          <h1 className="font-normal text-2xl lg:text-3xl font-Jockey_One">
            DISCOVER YOUR DARK SIDE{" "}
          </h1>
          <p className="font-medium text-lg md:text-xl pt-4 md:pt-6 font-Hind">
            HERBAL SPIRIT RE-PRESENT THE UNIQUE AND DIVERSE FLAVOURS OF
            SOUTH AFRICA AND IS INPIRED BY THE AJI PENCA CHILLI.
          </p>

          <div className="font-normal text-base pt-4 md:pt-9 font-Inter space-y-4">
            <p>
              Used in ancient ceremonies,these chillies were offered up in
              ancient rituals. they are red when ripe but turn black when dried
              on Andean Sun.
            </p>
            <p>
              These along with the spicy Locoto Pepper are carefully blended
              with our Cocalero botanicals to create a unquiuely spicy spririt.
              Negro is best served ice cold or with ginger beer.
            </p>
          </div>

          <div className="space-y-5 pt-4">
            <h1 className="font-bold font-Inter text-lg">SIGNATURE SERVER</h1>
            
            <div className="space-y-5 text-base font-normal">
              <p className="font-Inter text-base">
                <span className="font-semibold font-Inter">CHILLED SHOT</span>{" "}
                Keep it simple with a shot served at sub zero
              </p>
              <p className="font-Inter text-base">
                <span className="font-semibold font-Inter">SPICE BOMB</span>{" "}
                Using our signature glass, layer Cocalero Negro over ginger
                beer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CocalS;
