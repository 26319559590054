const Ourteam = () => {
  return (
    <main>
      <div className="flex flex-col lg:flex-row w-full p-5 justify-between lg:px-20 lg:gap-28 lg:h-[308px] bg-[#FBFBFB] ">
        <div>
          <h1 className="font-bold text-[32px] lg:pt-10 font-Inter">Join Our Team</h1>
          <p className="font-normal text-[17.72px] pt-5 pb-4 font-Inter">
            For More Information Contact
            <br />Contact Us
            <br />eodiboh@uridiumgroup.com
            <br />Phone No: 08163153384
          </p>
          <span className="font-medium text-[15.75px] text-[#1B460C] font-Inter ">See job openings</span>
        </div>

        <div className="">
          <h1 className="font-bold text-[32px] pt-3 lg:pt-10 font-Inter ">Enquiries</h1>
          <p className="font-normal text-[17.72px] pt-5 pb-3 font-Inter ">Please email eodiboh@uridiumgroup.com, and we’ll get back to you.</p>
          <span className="font-medium text-[15.75px] text-[#1B460C] font-Inter ">Contact Uridium Delight & Beverages</span>
        </div>
      </div>
    </main>
  );
};

export default Ourteam;
