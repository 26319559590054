const prod = {
    url: {
      BASENAME: process.env.REACT_APP_BASENAME,
    },
  };
  
  const dev = {
      url: {
        BASENAME: process.env.REACT_APP_BASENAME,
      }
  };
  
  export const config = process.env.NODE_ENV === 'development' ? dev : prod;