import "./stylingFiles.css"

const Aboutus = () => {
    return (
        <main>
            <div className="picker">
                <div className="font-Rubik font-black text-[50px] md:text-[90px] w-full text-white h-[483px] text-center place-content-center border ">ABOUT US</div>
            </div>
        </main>
    )
};

export default Aboutus;