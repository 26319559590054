import { Link } from "react-router-dom";
import Egans_Corrected from "../../assets/Video/Egans_Corrected.mp4";


const Exotic = () => {
  return (
    <main> 
      <div className="top-0 left-0 text-center relative w-full h-[100vh] overflow-hidden bg-[#000000B2] ">
        <video
          autoPlay
          loop
          muted
          playsInline
          tabIndex={-1}
          className="w-full h-[100vh] object-cover "
          controls={false}
        >
        <source
          src={Egans_Corrected}
          type="video/mp4"
        />
        </video>

        <div className=" absolute top-0 grid place-content-center bg-[#000000B2] w-full h-full ">
          <p className="text-center font-bold text-[30px] md:text-[80px] text-white font-Rubik">
          New Exotic Brands <br />
          For You at Uridium Delight & <br />
          Beverages
          </p>
          
          <div className="text-center pt-6">
          <button
              type="button"
              className="border-2 py-2 px-8 font-Rubik rounded-sm font-normal text-xl md:text-base text-white focus:outline-none border-white "
          >
              <Link to="/contact"> Contact Now </Link> 
          </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Exotic;
