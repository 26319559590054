import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/Images/Navbar/Logo.svg";
// import { Search } from 'lucide-react';
import { AlignJustify, X } from 'lucide-react';
import { useState } from "react";


const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const active = ({ isActive }) => (isActive ? "font-bold" : "font-normal").concat("text-white");

    const onToggleHandler = () => {
        setIsOpen(!isOpen)
    }
     
    return (
        //  <nav className="bg-black w-full py-2 md:px-10 flex flex-col lg:flex-row justify-between items-center text-white sticky z-10 top-0">
        //     <div className="flex items-center justify-between w-full px-2.5">
        //         <Link to="/">
        //             <img src={Logo} alt="Logo" className="md:w-[56.95px]" />
        //         </Link>

        //         <button onClick={onToggleHandler} type="button" className="inline-flex lg:hidden items-center justify-center  w-10 h-10 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        //             <span className="sr-only">Open main menu</span>
        //             {isOpen ? <X /> : <AlignJustify /> }
        //         </button>

        //         <div className={`hidden md:block w-full`}>
        //             <ul className="flex space-x-7 md:space-x-10 font-normal justify-center text-xs md:text-sm font-DM_Sans ">
        //                 <li>
        //                     <NavLink to="/product" className={ active  }>PRODUCT</NavLink>
        //                 </li>
        //                 <li>
        //                     <NavLink to="/about" className={ active }>ABOUT US</NavLink>
        //                 </li>
        //                 <li>
        //                     <NavLink to="/contact" className={ active }>CONTACT</NavLink>
        //                 </li>
        //             </ul>
        //         </div>
        //     </div>
            
        //     {/* <div className="relative">
        //         <div className="absolute inset-y-0 start-0 flex items-center md:ps-3 pointer-events-none">
        //             <Search className="w-4  md:h-4 text-black"/>
        //         </div>
        //         <input type="search" id="default-search" className="block lg:w-[414px] bg-[#bab8b8] text-black p-2 ps-10 text-sm placeholder:text-gray-900 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." required />
        //     </div> */}
        // </nav>
        <nav className="bg-black text-white lg:px-2 sm:px-4 lg:py-2.5 fixed w-full z-20 top-0 left-0">
            <div className="max-w-[85rem] border-b md:border-b lg:border-none flex py-3 lg:py-0 flex-wrap items-center justify-between lg:px-3  mx-auto">
                <Link to="/" className="flex items-center ml-4 lg:ml-0">
                    <img src={Logo} className="mr-3 md:w-[56.95px]" alt="uwl payment solution" />
                </Link>
                
                <div className="flex lg:order-2">
                    
                    <button onClick={() => setIsOpen(!isOpen)} data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 lg:hidden focus:outline-none mr-4 lg:mr-0">
                        <span className="sr-only">Open main menu</span>
                        {isOpen ? <X /> : <AlignJustify /> }
                    </button>
                </div>
                
                <div className={`${ isOpen ? "block" : "hidden" } lg:flex items-center justify-between duration-300 relative top-3 lg:top-0 w-full lg:w-auto lg:order-1 h-screen lg:h-auto`}>
                    <ul className="flex flex-col text-lg p-4 space-y-14 lg:space-y-0 mt-4 lg:flex-row lg:space-x-14 lg:mt-0 lg:text-sm lg:font-medium lg:border-0">
                        <li>
                            <NavLink to="/product" className={ active  }>PRODUCT</NavLink>
                        </li>
                         <li>
                            <NavLink to="/about" className={ active }>ABOUT US</NavLink>
                         </li>
                         <li>
                            <NavLink to="/contact" className={ active }>CONTACT</NavLink>
                         </li>
                    </ul>
                </div>
                
            </div>
        </nav>
    )
};

export default NavBar;